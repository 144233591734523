import React, { useContext, useEffect, useRef, useState } from 'react'
// @ts-ignore
import { Link } from 'gatsby'

import { ELocale } from '../types/index'
import { t } from './Translator'

// (!) REMARK: import enum from index.d.ts fails Gatsby build:
//     ERROR: Can't resolve '../types/index' in 'C:\Users\renaa\projects\ssk-gatsby-site\src\components'
//     solved by renaming index.d.ts to index.ts
import { ENavigationLink, IHamburgerButton, IHeaderNavigation, INavigationLink } from '../types/index'
import { links } from '../constants'
import { AppContext } from './ContextProvider'

const HamburgerButton: React.FC<IHamburgerButton> = ({ hamburgerMenuActive, toggleHamburgerMenu }) => {

    // you can remove the return if no code ends up here

    return (
        // d-lg-none = hide on screens wider than lg, see https://getbootstrap.com/docs/4.0/utilities/display/
        <div className="hamburger-container d-lg-none">
            <button type="button"
                    className={`hamburger hamburger-squeeze ${hamburgerMenuActive ? 'is-active' : ''}`}
                    onClick={toggleHamburgerMenu}>
                <span className="hamburger-box"><span className="hamburger-inner"></span></span>
            </button>
        </div>
    )
}

export const NavigationLink: React.FC<INavigationLink> = ({ type, to, label }) => {

    const context = useContext(AppContext)

    // (!) undefined checks to enable 'gatsby build'
    let navigationLink = { previous: '', current: '' }
    if(!context || !context.navigationLink) {
        // console.error(`# NavigationLink ${!context ? 'context' : 'context navigationLink'} undefined`)
    }
    else {
        navigationLink = context.navigationLink
    }

    const [isActive, setIsActive] = useState(false)

    let toggleUnderline = false
    if(!isActive) {
        toggleUnderline = to === '/' ? to === navigationLink.current : navigationLink.current.startsWith(to)
    }
    else {
        toggleUnderline = to === navigationLink.previous
    }

    useEffect(() => {

        // OK to set state in useEffect: https://stackoverflow.com/questions/53715465/can-i-set-state-inside-a-useeffect-hook
        // animated underlining if this link is currently active
        if(toggleUnderline) setIsActive(!isActive)

        /**
         (!) The empty set of dependencies, [], means that the effect will only run once when the component mounts,
         and not on every re-render. cfr. https://reactjs.org/docs/hooks-faq.html
         But don't we WANT useEffect to be called at every rerender to check if underline is still correct?
         e.g. at window resizing between desktop and mobile
         (!) 27/11/2020 logo link clicking several times -> 'Welcome'-underline flashes on/of infinite loop
         JS console error: index.js:2177
            Warning: Maximum update depth exceeded. This can happen when a component calls setState inside useEffect,
            but useEffect either doesn't have a dependency array, or one of the dependencies changes on every render.
         FIXED by adding the 2nd argument empty dependencies array []
         */
    }, [])

    const onClick = () => {
        context.setNewCurrentNavigationLink(to)
        // (!) check sessionStorage undefined to allow 'gatsby build'
        if(typeof sessionStorage !== 'undefined' && (type === ENavigationLink.Logo || type === ENavigationLink.Footer) )  {
            sessionStorage.setItem('hamburgerMenuWasActive', String(false))
        }
    }

    return (
        // (!) 'div' wrapper breaks styling
        <>
            {type === ENavigationLink.Logo ?
                <Link to={to} onClick={onClick}><img src="/images/logo_transparent.png" alt=""/></Link> :
                <li className={type !== ENavigationLink.Footer && isActive ? 'mobile-navigation-link-active' : ''}>
                    <Link to={to} onClick={onClick}>{label}</Link>
                    {type !== ENavigationLink.Footer &&
                        <div className={`navigation-link-underline ${isActive && 'active'}`}></div>}
                </li>
            }
        </>
    )
}

const HeaderNavigation: React.FC<IHeaderNavigation> = ( {hamburgerMenuActive} ) => {

    // console.log('** HeaderNavigation render hamburgerMenuActive['+hamburgerMenuActive+']')

    return (
        <nav className={`main-navigation mobile-navigation ${hamburgerMenuActive && 'active'}`} role="navigation">
            <div className={`mobile-navigation-horizontal-rule ${hamburgerMenuActive && 'active'}`}></div>
            <ul className="sf-menu">
                {links.map((link, index) =>
                    <NavigationLink key={index} to={link.to} label={t(link.label)} type={ENavigationLink.Header}/>
                )}
            </ul>
        </nav>
    )
}

const Header: React.FC = () => {

    const context = useContext(AppContext)

    // (!) check sessionStorage undefined to allow 'gatsby build'
    const sessionValue = typeof sessionStorage !== 'undefined' ? sessionStorage.getItem('hamburgerMenuWasActive') : 'false'
    const hamburgerMenuWasActive = !!sessionValue && sessionValue !== 'false'
    // console.log('** Header sessionValue['+sessionValue+'] hamburgerMenuWasActive['+hamburgerMenuWasActive+']')

    const [hamburgerMenuActive, setHamburgerMenuActive] = useState(hamburgerMenuWasActive)
    const [hamburgerMenuCloseAnimationDone, setHamburgerMenuCloseAnimationDone] = useState(!hamburgerMenuWasActive)
    const [isVisible, setIsVisible] = useState(true)

    // (!) undefined checks to enable 'gatsby build'
    let currentLanguage = String(ELocale.NL)
    if(!context || !context.locale) {
        // console.error(`# Header ${!context ? 'context' : 'context locale'} undefined`)
    }
    else {
        currentLanguage = context.locale.lang
    }

    const changeLanguage = (language: string) => {
        const newLocale = { lang: language, scrollTop: window.pageYOffset }
        console.log('# Header - changeLanguage - newLocale['+JSON.stringify(newLocale)+']')
        context.setLocale(newLocale)
    }

    const toggleHamburgerMenu = () => {
        const newValue = !hamburgerMenuActive
        // console.log('** Header toggleHamburgerMenu current hamburgerMenuActive['+hamburgerMenuActive+'] newValue['+newValue+']')
        setHamburgerMenuActive(newValue)
        sessionStorage.setItem('hamburgerMenuWasActive', String(newValue))
    }

    // (!) instance var for the component (otherwise reset after each re-render)
    const lastScrollTopRef = useRef(0)

    useEffect(() => {

        // console.log('** Header - useEffect - isVisible['+isVisible+'] hamburgerMenuActive['+hamburgerMenuActive+']')

        const isMobile = window.innerWidth <= 992
        if(isMobile && hamburgerMenuWasActive && !hamburgerMenuCloseAnimationDone && hamburgerMenuActive) {
            // console.log('* useEffect - hamburgerMenuActive['+hamburgerMenuActive+'] 'hamburgerMenuWasActive['+hamburgerMenuWasActive+']  -> CLOSE')
            setHamburgerMenuActive(false)
            setHamburgerMenuCloseAnimationDone(true)
        }

        let didScroll = false
        const handleScrollForHeader = (e: Event) => { didScroll = true }
        window.addEventListener('scroll', handleScrollForHeader)

        const intervalForHeader = setInterval(() => {
            if(didScroll) {
                didScroll = false
                const scrollTop = window.pageYOffset
                const lastScrollTop = lastScrollTopRef.current

                // when scrolling down (60 lower than siteHeader height)
                if (isVisible && (scrollTop > lastScrollTop) && (scrollTop > 60)) {
                    // console.log('# scrollTop['+scrollTop+'] lastScrollTop['+lastScrollTop+'] -> HIDE')
                    // hide header (style top:-100px; = topbar 40 + navbar 60)
                    setIsVisible(false)
                }
                // when scrolling up
                else if(!isVisible && (scrollTop < lastScrollTop)) {
                    // console.log('# scrollTop['+scrollTop+'] lastScrollTop['+lastScrollTop+'] -> SHOW')
                    // show (style top:0;)
                    setIsVisible(true)
                }
                lastScrollTopRef.current = scrollTop;
            }
        }, 200);

        return () => {
            clearInterval(intervalForHeader);
            window.removeEventListener('scroll', handleScrollForHeader)
        }
    })

    return (

        // todo rdm: several 'XML tag has empty body' may produce issues
        <div>
            <header className="site-header" style={{top: `${isVisible ? '0' : '-100px'}`}}>
                <div className="topbar">
                    <div className="container">
                        <div className="row">
                            <div className="col-md"><p></p></div>
                            <div className="col-md">
                                <ul className="locale-controls">
                                    {/* (!) REMARK Object.entries(ELocale).forEach() does not return an array -> must use .map()
                                            https://stackoverflow.com/questions/52843415/react-object-entries-renders-nothing */}
                                    {Object.keys(ELocale).map((key, id) =>
                                        <li key={id}>
                                            <a href="#" className={key === currentLanguage ? 'active' : ''}
                                               onClick={ () => changeLanguage(key) }>
                                                {key}
                                                {/*{id !== 2 && <span>{'|'}</span>}*/}
                                                {id !== 2 && <span></span>}
                                            </a>
                                        </li>
                                    )}
                                </ul>
                                <ul className="pull-right social-icons-colored">
                                    {/*using a href for external routing target="_blank" - security setting rel="noreferrer noopener"*/}
                                    {/*Gatsby link only for internal routing - does not support target="_blank" - see https://github.com/gatsbyjs/gatsby/issues/13825*/}
                                    <li className="facebook"><a href="https://www.facebook.com/SintSalvatorskathedraal" target="_blank" rel="noreferrer noopener">
                                        <i className="fa fa-facebook-f"></i></a>
                                    </li>
                                    {/*<li className="youtube"><a href="#"><i class="fa fa-youtube"></i></a></li>*/}
                                    {/*<li className="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>*/}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container sp-cont">
                    <div className="site-logo">
                        <div className={"logo-container"}>
                            <NavigationLink to={links[0].to} label={''} type={ENavigationLink.Logo}/>
                        </div>
                    </div>
                    <HamburgerButton hamburgerMenuActive={hamburgerMenuActive} toggleHamburgerMenu={toggleHamburgerMenu}/>
                    <HeaderNavigation hamburgerMenuActive={hamburgerMenuActive}/>
                </div>
            </header>
        </div>
    )
}

export default Header