import React, { useContext } from 'react'

import { AppContext } from './ContextProvider'

const translate = (content: object, key: string) => {

    const context = useContext(AppContext)

    // (!) undefined checks to enable 'gatsby build'
    let locale = 'nl'
    if(!context || !context.locale) {
        // console.error(`# Translator ${!context ? 'context' : 'context locale'} undefined for key[${key}]`)
    }
    else {
        locale = context.locale.lang.toLowerCase()
    }

    // @ts-ignore
    const result =  !!key ? content[key][locale] : content[locale]
    // console.log('# result == ' + result)
    return result
}

// t() function as in https://react.i18next.com/getting-started
const t = (content: object) => {
    // @ts-ignore
    return translate(content)
}

const t_ = (content: object, key: string) => {
    return translate(content, key)
}

export {
    t,
    t_
}